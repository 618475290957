import React from 'react';
import PropTypes from 'prop-types';
import { Link , graphql } from 'gatsby';
import Layout from '../components/Layout';
import { GatsbyImage , getImage } from "gatsby-plugin-image";
import '../style/career-subpage.sass';



const PageContent = ({
    backButton,
    fontColor,
    title,
    buttonLabel,
    buttonColor,
    logo,
    subDetails,
    questions,
    jobPurpose,
    requirements,
  }) => {

    const TitleFragment = ({ fragment, color }) => (
        <span className='
        ' style={{ color }}>{fragment + " "}</span>
      );
    
      const arrow = getImage(logo)
    
      return(
        <div className='careers-main-container'>
            <div className='careers-container'>
            <Link to='/careers'>
                <div className='careers-container-backbutton'>
                    {logo ? (
                    <GatsbyImage image={arrow} alt='Loading...' className='arrow'/>
                    ) : (
                    <img src={arrow} alt='Loading...' className='arrow'/>
                    )}
                        <p style={{color:fontColor , fontWeight: '500' , lineHeight: '1.2' , fontSize: `clamp(0.9rem , 1vw , 1.2rem)`}}>{backButton}</p>
                </div>
              </Link>
    
              <div className='careers-container-sub-1'>
                <p style={{color:buttonColor}}>{title}</p>
                <button style={{background:buttonColor}}>{buttonLabel}</button>
              </div>
              <div className='careers-container-sub-2'>
                <ul>
                  {subDetails.map((item,i) => (
                    <li style={{color:fontColor}} key={i}>{item.heading}:</li>
                  ))}
                </ul>
              </div>
    
              <div className='careers-container-sub-3-line'></div>
    
              <div className='careers-container-sub-3-question'>
                {questions.question1.map((value,index) => (
                    <TitleFragment key={index} {...value}/>
                ))}
                <p>{questions.answer1}</p>
              </div>
    
              <div className='careers-container-sub-3-question'>
                {questions.question2.map((value,index) => (
                      <TitleFragment key={index} {...value}/>
                ))}
                  <p>{questions.answer2}</p>
              </div>
    
              <div  className='careers-container-sub-3-job'>
                {jobPurpose.title.map((value,index) => (
                      <TitleFragment key={index} {...value}/>
                ))}
                <ul>
                  {jobPurpose.list1.map((list,unique) => (
                    <li key={unique}>{list.points}</li>
                  ))}
                </ul>
              </div>
    
              <div  className='careers-container-sub-3-job'>
                {requirements.title.map((value,index) => (
                      <TitleFragment key={index} {...value}/>
                ))}
                <ul>
                  {requirements.list2.map((list,unique) => (
                    <li key={unique}>{list.points}</li>
                  ))}
                </ul>
              </div>
    
            </div>
        </div>
      )
    }


export const CareerSubPageTemplate = ({
    backButton,
    fontColor,
    title,
    buttonLabel,
    buttonColor,
    logo,
    subDetails,
    questions,
    jobPurpose,
    requirements,    
  }) => {
    
    return (
      <div>
        <PageContent 
            backButton={backButton}
            fontColor={fontColor}
            title={title}
            buttonLabel={buttonLabel}
            buttonColor={buttonColor}
            logo={logo}
            subDetails={subDetails}
            questions={questions}
            jobPurpose={jobPurpose}
            requirements={requirements}
        />
      </div>
    );
  };


CareerSubPageTemplate.propTypes = {
    backButton: PropTypes.string.isRequired,
    fontColor: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    buttonColor: PropTypes.string.isRequired,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    subDetails: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    questions: PropTypes.shape({
      question1: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      answer1: PropTypes.string.isRequired,
      question2: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      answer2: PropTypes.string.isRequired,
    }).isRequired,
    jobPurpose: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      list1: PropTypes.arrayOf(
        PropTypes.shape({
          points: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    requirements: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      list2: PropTypes.arrayOf(
        PropTypes.shape({
          points: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  };


const CareerSubpage = ({ data }) => {
    const {frontmatter} = data.markdownRemark;
       
  return (
    <Layout>
      <CareerSubPageTemplate
        backButton = {frontmatter.backButton}
        fontColor = {frontmatter.fontColor}
        title = {frontmatter.title}
        buttonLabel = {frontmatter.buttonLabel}
        buttonColor = {frontmatter.buttonColor}
        logo= {frontmatter.logo}
        subDetails = {frontmatter.subDetails}
        questions= {frontmatter.questions}
        jobPurpose= {frontmatter.jobPurpose}
        requirements= {frontmatter.requirements}
      />
    </Layout>
  );
};

export default CareerSubpage;

export const pageQuery = graphql`
  query CareerSubPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "career-subpage" } }) {
      frontmatter {
        backButton
        fontColor
        title
        buttonLabel
        buttonColor
        logo {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        subDetails {
          heading
        }
        questions {
          question1 {
            fragment
            color
          }
          answer1
          question2 {
            fragment
            color
          }
          answer2
        }
        jobPurpose {
		  title {
            fragment
            color
          }
          list1 {
            points
          }
        }
        requirements {
		  title {
            fragment
            color
          }
          list2 {
            points
          }
        }
      }
    }
  }
`;

